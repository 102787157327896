<template>
  <fragment>
    <li class="menu-section">
      <h4 class="menu-text">Quản lý nhân sự</h4>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-if="currentUser.user.permission.includes(permission.KHOI_CO_SO)"
    >
      <a href="#" class="menu-link menu-toggle"
      >
        <i class="menu-icon fas fa-gopuram"></i>
        <span class="menu-text">Khối cơ sở</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              v-if="currentUser.user.permission.includes(permission.KHOI_CO_SO)"
          >
            <!-- <a href="#" class="menu-link menu-toggle">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Biểu đồ</span>
              <i class="menu-arrow"></i>
            </a> -->
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
              <ul class="menu-subnav">
                <!-- <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
          ]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Số lượng nhân sự</span>
                  </a>
                </li> -->
                <!-- <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
          ]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Tuyển dụng</span>
                  </a>
                </li> -->
                <!-- <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
          ]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Đào tạo</span>
                  </a>
                </li> -->
                <!-- <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
          ]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Nghỉ việc</span>
                  </a>
                </li> -->
              </ul>
            </div>
          </li>
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              v-if="currentUser.user.permission.includes(permission.QUAN_LY_NHAN_SU_KHOI_CO_SO)"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Quản lý</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
              <ul class="menu-subnav">
                <router-link
                    :to="{ name : 'user-index'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                      v-if="currentUser.user.permission.includes(permission.DANH_SACH_NHAN_SU)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">DS nhân sự</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name : 'sale-tree-view'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                      v-if="currentUser.user.permission.includes(permission.XEM_DANH_SACH_NHAN_SU_CO_SO)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Phân cấp sales</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name : 'move-branch'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
                      v-if="currentUser.user.permission.includes(permission.DIEU_CHUYEN_NHAN_SU)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Điều chuyển nhân sự</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name : 'log-resource-list'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
                      v-if="currentUser.user.permission.includes(permission.PHIEU_LUONG)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Phiếu lương</span>
                    </a>
                  </li>
                </router-link>
                <!-- <router-link
                    :to="{ name : 'pay-slip-teacher'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
                      v-if="currentUser.user.permission.includes(permission.PHIEU_LUONG)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Phiếu lương giảng viên</span>
                    </a>
                  </li>
                </router-link> -->
                <router-link
                    :to="{ name : 'sale-promotion'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                      v-if="currentUser.user.permission.includes(permission.SALE_LEN_CAP)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Sale lên cấp</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name : 'sale-degradation'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                      v-if="currentUser.user.permission.includes(permission.SALE_XUONG_CAP)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Sale xuống cấp</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name : 'amount-log-list'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                      v-if="currentUser.user.permission.includes(permission.XEM_DANH_SACH_DONG_TIEN)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Danh sách đóng tiền</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name : 'user-candidate'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                      v-if="currentUser.user.permission.includes(permission.UNG_VIEN)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Ứng viên</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name : 'BondManagementList'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                      v-if="currentUser.user.permission.includes(permission.TRAI_PHIEU)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Trái phiếu</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'list-propose-application' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                      v-if="
                      currentUser.user.permission.includes(
                        permission.DUYET_DON
                      )
                    "
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Duyệt đề xuất</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  :to="{ name : 'list-kpi-van-phong'}"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                    v-if="currentUser.user.permission.includes(permission.TRAI_PHIEU)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Upload KPI</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              v-if="currentUser.user.permission.includes(permission.BAO_CAO)"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Báo cáo</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
              <ul class="menu-subnav">
                <router-link
                    :to="{ name: 'employee' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Biến động nhân sự</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'sale-time-keep' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Chấm công sale</span>
                    </a>
                  </li>
                </router-link>
                <!-- <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    //            isActive && 'menu-item-active',
                    //            isExactActive && 'menu-item-active'
                  ]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Thu nhập</span>
                  </a>
                </li> -->
                <!-- <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
          ]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Lên xuống vị trí</span>
                  </a>
                </li> -->
                <!-- <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
          ]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Ra/vào</span>
                  </a>
                </li> -->
                <!-- <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
          ]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Kỷ luật</span>
                  </a>
                </li> -->
              </ul>
            </div>
          </li>
        </ul>
      </div>

    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-if="currentUser.user.permission.includes(permission.TRU_SO_TONG)"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-building"></i>
        <span class="menu-text">Trụ sở tổng</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <!-- <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              v-if="currentUser.user.permission.includes(permission.BIEU_DO)"
          > -->
          <!-- <a href="#" class="menu-link menu-toggle">
            <i class="menu-bullet menu-bullet-dot"><span></span></i>
            <span class="menu-text">Biểu đồ</span>
            <i class="menu-arrow"></i>
          </a> -->
          <!-- <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav"> -->
          <!-- <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
    ]"
          >
            <a href="#" class="menu-link">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Số lượng nhân sự</span>
            </a>
          </li> -->
          <!-- <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
    ]"
          >
            <a href="#" class="menu-link">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Tuyển dụng</span>
            </a>
          </li> -->
          <!-- <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
    ]"
          >
            <a href="#" class="menu-link">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Đào tạo</span>
            </a>
          </li> -->
          <!-- <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
    ]"
          >
            <a href="#" class="menu-link">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Nghỉ việc</span>
            </a>
          </li> -->
          <!-- </ul>
        </div>
      </li> -->
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              v-if="currentUser.user.permission.includes(permission.QUAN_LY_NHAN_SU_TRU_SO_TONG)"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Quản lý</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
              <ul class="menu-subnav">
                <!--                                <router-link-->
                <!--                                        :to="{ name : 'user-index'}"-->
                <!--                                        v-slot="{ href, navigate, isActive, isExactActive }"-->
                <!--                                >-->
                <!--                                    <li-->
                <!--                                            aria-haspopup="true"-->
                <!--                                            data-menu-toggle="hover"-->
                <!--                                            class="menu-item"-->
                <!--                                            :class="[-->
                <!--            isActive && 'menu-item-active',-->
                <!--            isExactActive && 'menu-item-active'-->
                <!--          ]"-->
                <!--                                    >-->
                <!--                                        <a :href="href" class="menu-link" @click="navigate">-->
                <!--                                            <i class="menu-bullet menu-bullet-dot"><span></span></i>-->
                <!--                                            <span class="menu-text">DS nhân sự</span>-->
                <!--                                        </a>-->
                <!--                                    </li>-->
                <!--                                </router-link>-->
                <!-- <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
//            isActive && 'menu-item-active',
          ]"
          v-if="currentUser.user.permission.includes(permission.CO_SO_VAT_CHAT_TRU_SO_TONG)"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Cơ sở vật chất</span>
                  </a>
                </li> -->
                <router-link :to="{ name : 'list-role-department'}"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                      v-if="currentUser.user.permission.includes(permission.QUAN_LY_DON_VI_TRU_SO_TONG)"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Quản lý đơn vị</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name : 'attendance-index'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="currentUser.user.permission.includes(permission.NHAN_SU_CHAM_CONG)"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Nhân sự chấm công</span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{ name : 'timekeeping-list'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                             v-if="currentUser.user.permission.includes(permission.QUAN_LY_CHAM_CONG)"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Quản lý chấm công</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'list-propose-application' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                    currentUser.user.permission.includes(permission.DON_DE_XUAT)
                  "
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Đơn đề xuất</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'ListManagementKpi' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    v-if="
                    currentUser.user.permission.includes(permission.QUAN_LY_KPI_NHAN_SU)
                  "
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text">Quản lý KPI nhân sự</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
          <!-- <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              v-if="currentUser.user.permission.includes(permission.BAO_CAO_TST)"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text">Báo cáo</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-classic menu-submenu-right">
              <ul class="menu-subnav">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
          ]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Thu nhập</span>
                  </a>
                </li>
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
          ]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Bảo hiểm</span>
                  </a>
                </li>
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
//            isActive && 'menu-item-active',
//            isExactActive && 'menu-item-active'
          ]"
                >
                  <a href="#" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Thưởng</span>
                  </a>
                </li>
              </ul>
            </div>
          </li> -->
        </ul>
      </div>
    </li>
    <router-link :to="{ name : 'jobs-list'}" class="menu-link menu-toggle"
                 v-slot="{ href, navigate, isActive, isExactActive }"
                 v-if="currentUser.user.permission.includes(permission.WEBSITE_TUYEN_DUNG)"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
      >

        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fab fa-chrome"></i>
          <span class="menu-text">Website tuyển dụng</span>
        </a>

      </li>
    </router-link>
    <router-link :to="{ name : 'company-event-list'}" class="menu-link menu-toggle"
                 v-slot="{ href, navigate, isActive, isExactActive }"
                 v-if="currentUser.user.permission.includes(permission.SU_KIEN_NOI_BO)">
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">

        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-calendar-check"></i>
          <span class="menu-text">Sự kiện nội bộ</span>
        </a>

      </li>
    </router-link>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-if="currentUser.user.permission.includes(permission.QUAN_LY_DON_VI_HANH_CHINH)"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-chart-area"></i>
        <span class="menu-text">Quản lý đơn vị hành chính</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link :to="{ name : 'area-index'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Khu vực</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link :to="{ name : 'office-index'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Quản lý tên văn phòng</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <!-- QUẢN LÝ NHÂN SỰ - QUẢN LÝ ĐÀO TẠO -->
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-if="currentUser.user.permission.includes(permission.QUAN_LY_DAO_TAO)"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-tag"></i>
        <span class="menu-text">Quản lý đào tạo</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link :to="{ name : 'policy-manage'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.PHAN_QUYEN_CHUONG_TRINH)"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Phân quyền chương trình</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'manage-candidate-teacher'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.QUAN_LY_DAO_TAO_UNG_VIEN_GIANG_VIEN)"

            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Gia nhập giảng viên</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'training-manage'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.CHUONG_TRINH_TVV_DINH_KY)"

            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Chương trình TVV định kỳ</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name : 'trainning-report'}"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                v-if="currentUser.user.permission.includes(permission.BAO_CAO_DAO_TAO)"

        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot"><span></span></i>
            <span class="menu-text">Báo cáo đào tạo</span>
          </a>
        </li>
      </router-link>
      <router-link :to="{ name : 'gen-z'}"
                   v-slot="{ href, navigate, isActive, isExactActive }">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
          v-if="currentUser.user.permission.includes(permission.WEB_GENZ)"

        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot"><span></span></i>
            <span class="menu-text">Web Genz</span>
          </a>
        </li>
      </router-link>
    </ul>
  </div>
</li>
    <router-link :to="{ name : 'honorsList'}" class="menu-link menu-toggle"
                 v-slot="{ href, navigate, isActive, isExactActive }"
                 v-if="currentUser.user.permission.includes(permission.CHUONG_TRINH_VINH_DANH)"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
      >

        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">Chương trình vinh danh</span>
        </a>

      </li>
    </router-link>
    <router-link :to="{ name : 'SuperDashIndex'}" class="menu-link menu-toggle"
                 v-slot="{ href, navigate, isActive, isExactActive }"
                 v-if="currentUser.user.permission.includes(permission.APP_GAME_CAMBRIDGE)"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
      >

        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">App game Cambridge</span>
        </a>

      </li>
    </router-link>
  </fragment>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "QuanLyNhanSu",
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
